.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  color: rgb(255, 255, 255);
  background: rgb(32, 27, 83);
  background: linear-gradient(
    rgba(32, 27, 83, 1) 0%,
    rgba(48, 110, 171, 1) 49%,
    rgba(34, 87, 153, 1) 100%
  );
  overflow-y: scroll;
}
.projects a {
  color: rgb(219, 126, 229);
  text-decoration: none;
}

.projects a:hover {
  color: rgb(88, 18, 96);
}

.projects__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  margin-top: 2%;
}

.projects h2 {
  margin: 2% 0%;
}

.projects__AccordionItem {
  border: none;
  color: white;
  width: 40vw;
  margin: 0.5% 0%;
}

.projects__AccordionHeader {
  width: 100%;
}

.projects__Button {
  margin-right: 1vw;
}

.projects__Header {
  display: flex;
  width: 40vw;
  align-items: left;
}

@media (max-width: 650px) {
  .projects {
    justify-content: center;
    padding-top: 10%;
  }

  .projects__AccordionItem {
    color: white;
    border: none;
    width: 70vw;
    margin: 5% 0%;
  }
}
