ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10%;
}

h1,
h2,
h3 {
  margin: 0;
}

.App {
  font-family: "Nanum Myeongjo", serif;
  font-family: "Noto Sans KR", sans-serif;
  text-align: center;
}

.scrollSnap {
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;
}

.scrollSnap::-webkit-scrollbar {
  display: none;
}

.scrollSnap__Element {
  scroll-snap-align: start;
  height: 100vh;
  width: 100%;
  display: flex;
}

.scrollSnap__ElementWeb {
  scroll-snap-align: start;
  height: 100vh;
  width: 100%;
  display: flex;
}
.scrollSnap__ElementMobile {
  display: none;
}
@media (max-width: 650px) {
  .scroll_Snap {
    display: none;
  }
}

.scrollSnap__Seoul {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-image: url("https://i.imgur.com/hLsIDHK.jpg");
  background-size: cover;
  position: relative;
}

.header {
  z-index: 1;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2w;
  width: 100%;
  padding: 1% 0%;
  opacity: 0.2;
  transition: opacity 1.6s;
}

.header:hover,
.header.active {
  opacity: 1;
}

.header__Nav {
  display: flex;
  padding-left: 5%;
  z-index: 2;
}

.header__Nav a {
  text-decoration: none;
}

.Modal {
  height: 20vh;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.project {
  margin: 0 auto;
  cursor: pointer;
}

.project__Image {
  width: 200px;
  height: 100%;
  opacity: 0.6;
}

.project__Image:hover {
  opacity: 1;
}

.carousel {
  background-color: black;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2%;
  margin-bottom: 8%;
  width: 100%;
}

.carousel__Image {
  width: 650px;
  height: 420px;
  object-fit: contain;
}

.slide__next {
  opacity: 0;
  transition: opacity 1.6s;
}

.slide__next.active {
  opacity: 1;
}

.carousel__Btn {
  position: absolute;
  font-size: 3em;
  color: white;
}
.arrowDisplay__Web {
  display: block;
}

.arrowDisplay__Mobile {
  display: none;
}

@media (max-width: 650px) {
  .scrollSnap {
    max-height: 100vh;
    scroll-snap-type: none;
    position: relative;
  }

  .scrollSnap__Seoul {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-image: url("https://i.imgur.com/hLsIDHK.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
  }
}

.accordion-item {
  display: flex;
  flex-direction: column;
  text-align: start;
}
