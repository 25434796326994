body {
  margin: 0;
  font-family: "Nanum Myeongjo", serif;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.header a {
  color: white;
  text-decoration: none;
}

.backdrop {
  height: 100vh;
  width: 100%;
  color: rgb(255, 255, 255);
  background: rgb(32, 27, 83);
  background: linear-gradient(
    rgba(32, 27, 83, 1) 0%,
    rgba(48, 110, 171, 1) 49%,
    rgba(58, 130, 188, 1) 100%
  );
}

.component {
  background: rgb(246, 252, 252);
}
